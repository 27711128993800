/* @flow */

import { FUNDING } from "./funding";

export const APM_LIST = [
  FUNDING.IDEAL,
  FUNDING.BANCONTACT,
  FUNDING.GIROPAY,
  FUNDING.SOFORT,
  FUNDING.EPS,
  FUNDING.MYBANK,
  FUNDING.P24,
  FUNDING.PAYU,
  FUNDING.BLIK,
  FUNDING.TRUSTLY,
  FUNDING.ZIMPLER,
  FUNDING.MAXIMA,
  FUNDING.OXXO,
  FUNDING.BOLETO,
  FUNDING.BOLETOBANCARIO,
  FUNDING.WECHATPAY,
  FUNDING.MERCADOPAGO,
  FUNDING.MULTIBANCO,
];
