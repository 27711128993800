import React from 'react';
import { FUNDING } from '@paypal/sdk-constants';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const clientID = "AbF1oSamds_jxuFCyWCzpLx4diZ_-cplaeYCWmkdw0WinR7DOfXHsdhQYmT_5ztKy6komGPSOwuh15gL";
const defaultDonation = 25;

class Donation extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            amount: defaultDonation, 
            completeDonation: false, 
            firstName: "", 
            donation: 0 
        };
        this.createOrder = this.createOrder.bind(this);
        this.onApprove = this.onApprove.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(newAmount) {
        this.setState({ amount: Math.floor(newAmount * 100) / 100 });
    }

    createOrder(data, actions) {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: this.state.amount,
                        currency_code: "USD",
                        breakdown: {
                            item_total: {
                                currency_code: "USD",
                                value: this.state.amount,
                            }
                        }
                    },
                    items: [
                        {
                            name: "Arachne Theater donation",
                            quantity: 1,
                            unit_amount: {
                                value: this.state.amount,
                                currency_code: "USD",
                            },
                            category: "DONATION"
                        }
                    ]
                }
            ]
        });
    }

    onApprove(data, actions) {
        Promise.all([actions.order.capture(), actions.order.get()])
            .then(resp => {
                let orderDetails = resp[1];
                let confirmedAmount = orderDetails.purchase_units[0].amount;
                this.setState({
                    firstName: orderDetails.payer.name.given_name,
                    donation: confirmedAmount.currency_code + " " + confirmedAmount.value,
                    completeDonation: true
                });
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        timestamp: Date.now(),
                        firstName: orderDetails.payer.name.given_name,
                        lastName: orderDetails.payer.name.surname,
                        emailAddress: orderDetails.payer.email_address,
                        currency: confirmedAmount.currency_code,
                        amount: confirmedAmount.value
                    })
                };
                fetch('api/saveDonation', requestOptions);
            });
    }

    render() {
        const { amount, completeDonation, firstName, donation } = this.state;
        let donationBox = completeDonation ?
            <div className="right-section">
                <span className="amount">
                    <span className="label">
                        Thank you {firstName} for your
                    </span>
                </span>
                <span className="amount">
                    <span className="label">
                        {donation} donation!
                    </span>
                </span>
            </div>
            :
            <div className="right-section">
                <span className="amount">
                    <span className="label">
                        Amount
                    </span>
                    <span className="input-dollars left">
                        <input type="number"
                            value={amount}
                            onChange={(ev) => this.handleChange(ev.target.value)}
                        />
                    </span>
                </span>
                <PayPalScriptProvider options={{ "client-id": clientID }}>
                    <PayPalButtons
                        fundingSource={FUNDING.PAYPAL}
                        style={{ label: "donate" }}
                        createOrder={(data, actions) => this.createOrder(data, actions)}
                        onApprove={(data, actions) => this.onApprove(data, actions)}
                    />
                </PayPalScriptProvider>
            </div>
        return (
            <div>
                {donationBox}
            </div>
        );
    }
}

export default Donation;