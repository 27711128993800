/* @flow */

export const INTENT = {
  CAPTURE: ("capture": "capture"),
  AUTHORIZE: ("authorize": "authorize"),
  ORDER: ("order": "order"),
  TOKENIZE: ("tokenize": "tokenize"),
  SUBSCRIPTION: ("subscription": "subscription"),
};

export const COMMIT = {
  TRUE: (true: true),
  FALSE: (false: false),
};

export const VAULT = {
  TRUE: (true: true),
  FALSE: (false: false),
};

export const CURRENCY = {
  AED: ("AED": "AED"),
  ALL: ("ALL": "ALL"),
  ANG: ("ANG": "ANG"),
  AOA: ("AOA": "AOA"),
  AUD: ("AUD": "AUD"),
  AWG: ("AWG": "AWG"),
  BAM: ("BAM": "BAM"),
  BBD: ("BBD": "BBD"),
  BGN: ("BGN": "BGN"),
  BIF: ("BIF": "BIF"),
  BMD: ("BMD": "BMD"),
  BND: ("BND": "BND"),
  BOB: ("BOB": "BOB"),
  BRL: ("BRL": "BRL"),
  BSD: ("BSD": "BSD"),
  BTN: ("BTN": "BTN"),
  CAD: ("CAD": "CAD"),
  CDF: ("CDF": "CDF"),
  CHF: ("CHF": "CHF"),
  CLP: ("CLP": "CLP"),
  COP: ("COP": "COP"),
  CRC: ("CRC": "CRC"),
  CVE: ("CVE": "CVE"),
  CZK: ("CZK": "CZK"),
  DJF: ("DJF": "DJF"),
  DKK: ("DKK": "DKK"),
  DOP: ("DOP": "DOP"),
  DZD: ("DZD": "DZD"),
  EGP: ("EGP": "EGP"),
  ETB: ("ETB": "ETB"),
  EUR: ("EUR": "EUR"),
  FJD: ("FJD": "FJD"),
  FKP: ("FKP": "FKP"),
  GBP: ("GBP": "GBP"),
  GIP: ("GIP": "GIP"),
  GMD: ("GMD": "GMD"),
  GNF: ("GNF": "GNF"),
  GTQ: ("GTQ": "GTQ"),
  GYD: ("GYD": "GYD"),
  HKD: ("HKD": "HKD"),
  HNL: ("HNL": "HNL"),
  HRK: ("HRK": "HRK"),
  HUF: ("HUF": "HUF"),
  IDR: ("IDR": "IDR"),
  ILS: ("ILS": "ILS"),
  INR: ("INR": "INR"),
  ISK: ("ISK": "ISK"),
  JMD: ("JMD": "JMD"),
  JPY: ("JPY": "JPY"),
  KES: ("KES": "KES"),
  KMF: ("KMF": "KMF"),
  KRW: ("KRW": "KRW"),
  KYD: ("KYD": "KYD"),
  LAK: ("LAK": "LAK"),
  LKR: ("LKR": "LKR"),
  MDL: ("MDL": "MDL"),
  MGA: ("MGA": "MGA"),
  MKD: ("MKD": "MKD"),
  MNT: ("MNT": "MNT"),
  MRO: ("MRO": "MRO"),
  MUR: ("MUR": "MUR"),
  MVR: ("MVR": "MVR"),
  MXN: ("MXN": "MXN"),
  MYR: ("MYR": "MYR"),
  NAD: ("NAD": "NAD"),
  NIO: ("NIO": "NIO"),
  NOK: ("NOK": "NOK"),
  NPR: ("NPR": "NPR"),
  NZD: ("NZD": "NZD"),
  PEN: ("PEN": "PEN"),
  PGK: ("PGK": "PGK"),
  PHP: ("PHP": "PHP"),
  PLN: ("PLN": "PLN"),
  PYG: ("PYG": "PYG"),
  QAR: ("QAR": "QAR"),
  RON: ("RON": "RON"),
  RSD: ("RSD": "RSD"),
  RUB: ("RUB": "RUB"),
  SAR: ("SAR": "SAR"),
  SBD: ("SBD": "SBD"),
  SCR: ("SCR": "SCR"),
  SEK: ("SEK": "SEK"),
  SGD: ("SGD": "SGD"),
  SHP: ("SHP": "SHP"),
  SLL: ("SLL": "SLL"),
  SOS: ("SOS": "SOS"),
  SRD: ("SRD": "SRD"),
  SZL: ("SZL": "SZL"),
  THB: ("THB": "THB"),
  TJS: ("TJS": "TJS"),
  TOP: ("TOP": "TOP"),
  TTD: ("TTD": "TTD"),
  TWD: ("TWD": "TWD"),
  TZS: ("TZS": "TZS"),
  USD: ("USD": "USD"),
  UYU: ("UYU": "UYU"),
  VND: ("VND": "VND"),
  VUV: ("VUV": "VUV"),
  WST: ("WST": "WST"),
  XAF: ("XAF": "XAF"),
  XCD: ("XCD": "XCD"),
  YER: ("YER": "YER"),
};
