/* @flow */

export const ENV = {
  LOCAL: ("local": "local"),
  STAGE: ("stage": "stage"),
  SANDBOX: ("sandbox": "sandbox"),
  PRODUCTION: ("production": "production"),
  TEST: ("test": "test"),
};

export const MOBILE_ENV = {
  ANDROID: ("android": "android"),
  IOS: ("iOS": "iOS"),
};
