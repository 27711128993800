import React from 'react';
import logo from './img/arachne_logo.png';
import Donation from './components/Donation';

class App extends React.Component {

  render() {
    return (
      <div className="app">
        <header className="header-section">
          <div className="left-section">
            <img className="logo" src={logo} alt="Arachne Theater Logo" />
            <p className="text-title">Arachne Theater
              <span className="text-content">Productions</span>
            </p>
          </div>
          <Donation />
        </header>
        <div className="main-background"></div>
        <div className="main-section">
          <div className="text-announce">
            Coming soon!
          </div>
        </div>
      </div>
    );
  }
}

export default App;
